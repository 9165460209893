<template>
    <div
        class="chips-list-item ElevationDark__1"
        :class="{active}"
        @click="$emit('update',item.id)">
        <div class="title label__medium">{{ item.title }}</div>
        <div class="inner body__extrasmall">
            <div class="progress-wrapper" :class="precentCount == 100 ? 'fullfield' : ''">
                <svg>
                    <circle></circle>
                    <circle :style="{ 'stroke-dashoffset': progressCalc(precentCount) }"></circle>
                </svg>
                <i class="material-symbols-outlined">check</i>
            </div>
            <div> {{tokens.newDesign.uploaded }} {{ item.uploadedCount }}/{{ item.documentCount }}</div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: "ProgressChipsListItem",
    props: {
        item: {
            type: Object,
        },
        active: {
            type: Boolean,
        }
    },
    methods: {
        progressCalc(percentage) {
            const startValue = 294;
            const endValue = 255;
            return startValue - (percentage / 100) * (startValue - endValue);
        },

    },
    computed: {
        ...mapGetters(['tokens']),
        precentCount() {
            return (this.item.uploadedCount / this.item.documentCount) * 100
        }
    }
}
</script>

<style scoped>
.chips-list-item {
    background: var(--System-Surface1);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 12px;
    gap: 6px;
    color: var(--System-Outline);
    border: 1px solid var(--System-OutlineVariant, #3F454F);
    cursor: pointer;
    transform: all .3s ease;
    border-radius: var(--Corner-small);
    min-width: 100px;
    max-height: 60px;
}

.chips-list-item:hover {
    background: var(--Statelayers-surfacevariant-opacity08);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.chips-list-item:active {
    background: var(--Statelayers-onsurfacevariant-opacity12);
}

.chips-list-item.active {
    border-color: var(--System-Primary);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.chips-list-item.active .title {
    color: var(--System-Primary)
}

.title {
    color: var(--System-OnSurface)
}

.inner {
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
}

.progress-wrapper {
    position: relative;
    width: 1em;
    height: 1em;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-wrapper.fullfield:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--System-Primary);
    z-index: 2;
}

.progress-wrapper.fullfield:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--System-Primary);
    color: black;
    z-index: 2;
}

.progress-wrapper i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    font-size: .7em;
    font-weight: bold;
    color: var(--System-OnPrimary);
    opacity: 0;
    margin-top: 1px;
}

.progress-wrapper.fullfield i {
    opacity: 1;
}

svg {
    position: relative;
    width: 1em;
    height: 1em;
    transform: rotate(-88deg);
}

svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: var(--System-SecondaryContainer);
    stroke-width: .1em;
    stroke-linecap: round;
    cx: .5em;
    cy: .5em;
    r: .45em;
}

svg circle:nth-child(2) {
    stroke-dasharray: 3em;
}

svg circle:nth-child(2) {
    stroke-dashoffset: 294;
    stroke: var(--System-Primary);
}

@media(max-width: 768px) {
    .chips-list-item {
        padding: 12px 8px;
    }
}
</style>
