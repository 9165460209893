<script>
export default {
    props: {
        buttonText: {
            type: String,
        },
        buttonStyle: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    name: "BaseButton"

}
</script>

<template>
    <button class="button-standard" :disabled="disabled" :class="buttonStyle">
        <div class="state-layer">
            <span class="label__large" v-if="buttonText">{{buttonText}}</span>
            <slot name="icon"/>
        </div>
    </button>
</template>

<style scoped>
.button-standard,
.button-standard .state-layer {
    border-radius: var(--Corner-Extra-small);
    cursor: pointer;
}

.state-layer {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    width: 100%;
}

.state-layer i {
    font-size: 18px;
    padding: 2.5px 0;
    margin: 0 -5px;
}

.button-standard:disabled,
.button-standard:disabled .state-layer {
    cursor: not-allowed;
}

.button-standard.text-tertiary {
    color: var(--System-Tertiary);
}

.button-standard.text-tertiary:hover .state-layer{
    background: var(--Statelayers-tertiary-opacity08);
}

.button-standard.elevated:focus-visible,
.button-standard.text-tertiary:focus-visible .state-layer {
    background: var(--Statelayers-tertiary-opacity12);
}

.button-standard.elevated:active,
.button-standard.text-tertiary:active .state-layer {
    background: var(--Statelayers-tertiary-opacity16);
}

.button-standard.text-tertiary:disabled .state-layer {
    background: transparent;
    color: var(--System-On-Surface);
}

.button-standard.elevated {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    background: var(--System-Surface2);
    color: var(--System-Tertiary);
}

.button-standard.elevated:hover .state-layer {
    background: var(--Statelayers-tertiary-opacity08);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
}

.button-standard.elevated:disabled .state-layer {
    background: var(--State-layers-on-surface-opacity12);
    box-shadow: none;
    color: var(--System-On-Surface);
}

.button-standard.on-surface .state-layer {
    background: var(--System-Surface2);
    color: var(--System-OnSurfaceVariant);
}

.button-standard.on-surface:hover .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.button-standard.on-surface:focus-visible .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity12);
}

.button-standard.on-surface:active .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity16);
}

.button-standard.on-surface:disabled .state-layer {
    background: var(--Statelayers-onsurface-opacity12);
}

.button-standard.filled-primary  {
    background: var(--System-Primary);
    color: var(--System-OnPrimary);
}

.button-standard.filled-primary:hover .state-layer {
    background: var(--Statelayers-onprimary-opacity08);
}

.button-standard.filled-primary:focus-visible .state-layer {
    background: var(--Statelayers-onprimary-opacity12);
}

.button-standard.filled-primary:active .state-layer {
    background: var(--Statelayers-onprimary-opacity16);
}

.button-standard.filled-primary:disabled {
    opacity: .38;
    background: var(--Statelayers-onsurface-opacity12);
    color: var(--System-OnSurface);
}

.button-standard.outlined-primary {
    border: 1px solid var(--System-Outline);
    color: var(--System-Primary);
}

.button-standard.outlined-primary:active {
    border: 1px solid var(--System-Primary);
}

.button-standard.outlined-primary:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.button-standard.outlined-primary:focus-visible .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.button-standard.outlined-primary:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.button-standard.outlined-primary:disabled {
    border: 1px solid var(--Statelayers-onsurface-opacity12);
    color: var(--System-OnSurface);
}

.button-standard.text-primary {
    color: var(--System-Primary);
}

.button-standard.text-primary:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.button-standard.text-primary:focus-visible .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.button-standard.text-primary:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.button-standard.text-primary:disabled {
    color: var(--System-OnSurface);
}

.button-standard.filled-tertiary  {
    background: var(--System-Tertiary);
    color: var(--System-OnTertiary);
}

.button-standard.filled-tertiary:hover .state-layer {
    background: var(--Statelayers-onprimary-opacity08);
}

.button-standard.filled-tertiary:focus-visible .state-layer {
    background: var(--Statelayers-onprimary-opacity12);
}

.button-standard.filled-tertiary:active .state-layer {
    background: var(--Statelayers-onprimary-opacity16);
}

.button-standard.filled-tertiary:disabled {
    opacity: .38;
    background: var(--Statelayers-onsurface-opacity12);
    color: var(--System-OnSurface);
}

.button-standard.outlined-tertiary {
    border: 1px solid var(--System-Outline);
    color: var(--System-Tertiary);
}

.button-standard.outlined-tertiary:active {
    border: 1px solid var(--System-Tertiary);
}

.button-standard.outlined-tertiary:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.button-standard.outlined-tertiary:focus-visible .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.button-standard.outlined-tertiary:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.button-standard.outlined-tertiary:disabled {
    border: 1px solid var(--Statelayers-onsurface-opacity12);
    color: var(--System-OnSurface);
}
</style>
