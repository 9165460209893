<template>
    <div class="credit-debit-tab">
        <BasePanel>
            <template #content>
                <template v-if="!successful">
                    <div class="amount-to">
                        <h3 class="title title__medium">{{ tokens.newDesign.amountToWithdrawal }}</h3>
                        <div class="inputs-list">
                            <BaseInput v-model="amount" :type="'number'" :title="tokens.newDesign.amount" :placeholder="'0.00'">
                                <template #icon>
                                    <p class="body__large">$</p>
                                </template>
                                <template #info>
                                    <p class="body__large">USD</p>
                                </template>
                            </BaseInput>
                        </div>
                    </div>

                    <div class="bank-details">
                        <h3 class="title title__medium">Bank Details</h3>
                        <div class="inputs-list">
                            <BaseInput v-model="account" title="Account Number"/>
                            <BaseInput v-model="holder" title="Account Holder Name"/>
                            <BaseInput v-model="iban" title="IBAN"/>
                            <BaseInput v-model="swift" title="Bank Swift Cpde"/>
                            <BaseInput v-model="recipientAddress" title="Recipient Address"/>
                            <BaseInput v-model="routingNumber" title="Routing Number"/>
                            <BaseInput v-model="bankBeneficiary" title="Bank of Beneficiary Name"/>
                            <BaseInput v-model="bankAddress" title="Bank Address"/>
                            <BaseInput v-model="bankCode" title="Bank code (BSB)"/>
                            <BaseInput v-model="desc" title="Description"/>
                        </div>
                    </div>
                   
                    <div class="confirm">
                        <SendWithdrawal
                            :disabled="!amount || !holder || !account"
                            :info="getInfo"
                            @send="showSuccessful()"
                        />
                    </div>
                </template>
                <template v-else>
                    <SuccessfulTab
                        :details="getDetails"
                        @closeSuccessfullTab="closeSuccessfullTab"
                        @showAccounts="showAccounts"
                    />
                </template>
            </template>
        </BasePanel>
    </div>
</template>

<script>
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput.vue"
import SendWithdrawal from "@/new-design/features/Withdrawal/ui/SendWithdrawal.vue"
import {mapGetters} from "vuex"
import SuccessfulTab from "@/new-design/pages/withdrawal/tabs/SuccessfulTab.vue"
import {currencySign} from "@/common/helpers"
import {MODAL, MODAL_FULLSCREEN} from "@/store/mutations.type"

export default {
    name: "CreditDebitTab",
    components: {SuccessfulTab, SendWithdrawal, BaseInput, BasePanel},
    mounted() {
        this.init()
    },
    data() {
        return {
            amount: null,
            account: "",
            holder: "",
            iban: "",
            swift: "",
            recipientAddress: "",
            routingNumber: "",
            bankBeneficiary: "",
            bankAddress: "",
            bankCode: "",
            desc: "",
            successful: false,
        }
    },
    methods: {
        init() {
            this.account =  "";
            this.holder =  "";
            this.iban =  "";
            this.swift =  "";
            this.recipientAddress = "",
            this.routingNumber = "",
            this.bankBeneficiary = "",
            this.bankAddress = "",
            this.bankCode = "",
            this.desc = "",
            this.successful = false
        },
        showSuccessful() {
            this.successful = true
        },
        showAccounts() {
            this.$store.commit(MODAL, {
                fullscreen: true
            })

            this.$store.commit(MODAL, {
                hamburgerMenu: false
            })

            this.$store.commit(MODAL_FULLSCREEN, {
                page: 'accounts'
            })
        },
        closeSuccessfullTab() {
            this.init()
            this.successful = false
        },
        currencySign
    },
    computed: {
        ...mapGetters(['activeAccount','tokens']),
        getInfo() {
            return {
                method: 'bank',
                amount: this.amount,
                account: this.account,
                holder: this.holder,
                iban: this.iban,
                recipientAddress: this.recipientAddress,
                routingNumber: this.routingNumber,
                bankBeneficiary: this.bankBeneficiary,
                bankAddress: this.bankAddress,
                bankCode: this.bankCode,
                desc: this.desc,
                activeAccount: this.activeAccount
            }
        },
        getDetails() {
            return [
                {
                    title: 'Amount withdrawn',
                    value: currencySign(this.activeAccount.currency) + ' ' + this.amount,
                },
                {
                    title: 'Currency',
                    value: this.activeAccount.currency
                },
                {
                    title: 'Time',
                    value: this.formattedWithdrawalTime
                },
                {
                    title: 'Transaction status',
                    value: 'Processing'
                },
            ]
        },
        formattedWithdrawalTime() {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            }
            return new Date().toLocaleString('en-GB', options).replace(',', '')
        },
    }
}
</script>


<style scoped>
.title {
    color: var(--System-OnSurface);
    margin-bottom: 16px;
}

.amount-to,
.card-details {
    margin-bottom: 16px;
}

.cards-list {
    display: flex;
    align-items: center;
    gap: 4px;
}

.inputs-list {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.confirm {
    margin-top: 24px;
    width: 100%;
    text-align: right;
}
</style>
